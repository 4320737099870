<template>
    <div class="text-overflow">{{ item.name }}</div>
    <div class="text-overflow">{{ item.lastname }}</div>
    <div class="text-overflow">{{ item.contactPhone }}</div>
    <div class="text-overflow">{{ item.email }}</div>
    <div class="text-overflow user-status-holder"><span class="user-status" :class="[status.class]">{{ status.value }}</span></div>
</template>

<script>
import moment from 'moment';
    export default {
        emits: ['viewDrivers'],
        props: ['item'],
        computed: {
            status() {
                return this.item.removed ? { value: 'Removed', class: 'removed' } : this.item.pending ? { value: 'Invited', class: 'pending' } : { value: 'Active', class: 'active' };
            }
        }
    }
</script>

<style lang="scss" scoped>

.user-status-holder {
    display: flex;
}

.user-status {
    padding: 0 10px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 11px;
    font-size: 10px;
    &.active {
        color: #fff;
        background: $success;
    }
    &.removed {
        background: $error;
        color: #fff;
    }
    &.pending {
        background: $themeColor2;
        color: #fff;
    }
}
</style>