<template>
    <div class="wrapper grid row-50">
        
        <ActionBar :title="pageTitle">
            <Button text="Add Driver" size="small" @click="saveDriver({})"/>
        </ActionBar>


        <div class="roles-content grid row-20">

            <PageSearchInput v-model="_pageManager.query.search" placeholder="Search drivers..."/>

            <div class="page-filters">
                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value" showAttribute="name" :options="[{ value: null, name: 'All' }, { value: 'active', name: 'Active' }, { value: 'removed', name: 'Removed' }]"/>
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetDrivers'])">
                    <TableRow 
                        :size="columnsSizes" 
                        :key="item._id" 
                        v-for="(item, key) of _pageManager.list" 
                        @dblclick="saveDriver(item, key)"
                        :menu="getDriverMenu(item)"
                        @edit="saveDriver(item, key)"
                        @remove="removeDriver(item)"
                        @restore="restoreDriver(item)"
                    >
                        <DriverItem :item="item"/>
                    </TableRow>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetDrivers'])" class="spinner-flex"><Spinner/></div>
                    <div v-else><NoDataMessage text="Nothing found"/></div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
import DriverItem from '../../components/Carriers/Drivers/DriverItem.vue'

import SaveDriverView from '../../components/Carriers/Drivers/SaveDriverView.vue'

    export default {
        mixins: [PaginationJS],
        components: {
            Pagination,
            DriverItem
        },
        data() {
            return {
                tableHead: [
                    { name: 'Name' },
                    { name: 'Last name' },
                    { name: 'Contact phone' },
                    { name: 'Email address' },
                    { name: 'Status' },
                    { name: '' },
                ],
                columnsSizes: [ 'minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)', 'minmax(50px, 1fr)','minmax(50px, 120px)','34px' ],
                carrier: null
            }
        },
        watch: {
            "_pageManager.query.search": {
                deep: true,
                handler() {
                    this._pageManager.query.page = 1;
                }
            },
            "_pageManager.query": {
                deep: true,
                handler() {
                    this.getDrivers();
                }
            }
        },
        computed: {
            carrierId() {
                return this.$route.params.id;
            },
            pageTitle() {
                if(!this.carrier) return 'Drivers';
                return this.carrier.companyName + ' - Drivers';
            }
        },
        methods: {
            getCarrierDetails() {
                this.ajax('GetCarrierDetails', {
                    url: `/dispatch/carriers/${this.carrierId}`,
                    method: 'GET',
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.carrier = body;
                });
            },
            getDriverMenu(item) {
                let array = [{ name: 'Edit driver', emit: 'edit' }];
                
                if(item.removed) {
                    array.push({ name: 'Restore driver', emit: 'restore' })
                } else {
                    array.push({ name: 'Remove driver', emit: 'remove', type: 'remove' })
                }

                return array;
            },
            getDrivers() {
                let query = new URLSearchParams(this._pageManager.query).toString();

                this.ajax('GetDrivers', {
                    url: `/dispatch/carrier/drivers/${this.carrierId}?` + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this._pageManager.pages = body.pages;
                        this._pageManager.count = body.count;
                        this._pageManager.list = body.list;
                        this._pageManager.page = body.page;
                        this._pageManager.hasNextPage = body.hasNextPage;
                        this._pageManager.hasPrevPage = body.hasPrevPage;
                    }
                });
            },
            async removeDriver(item) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to remove this driver?');
                if(!confirmRemove) return;

                await this.ajax('RemoveDriver', {
                    url: `/dispatch/carrier/drivers/remove`,
                    method: 'DELETE',
                    data: {
                        _id: item._id
                    }
                }, (err, body) => { });

                item.removed = true;
                this.getDrivers();
            },
            async restoreDriver(item) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to restore this driver?');
                if(!confirmRemove) return;

                await this.ajax('RestoreDriver', {
                    url: `/dispatch/carrier/drivers/restore`,
                    method: 'PUT',
                    data: {
                        _id: item._id
                    }
                }, (err, body) => { });

                item.removed = false;
                this.getDrivers();
            },
            saveDriver(driver = {}) {

                driver = JSON.parse(JSON.stringify(driver));
                let carrierID = this.carrierId;

                let modal = this.$ShowModal({
                    title: driver._id ? 'Update Driver' : 'Add Driver',
                    description: driver._id ? 'Update driver details' : 'Enter driver details',
                    component: SaveDriverView,
                    props: {
                        item: driver,
                        errors: {}
                    }
                });

                modal.onClose(() => {
                    this.getDrivers();
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    await this.ajax('SaveDriver', {
                        url: '/dispatch/carrier/drivers',
                        method: 'POST',
                        data: {
                            ...props.item,
                            carrier: carrierID
                        }
                    }, (err, body) => {
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                    });

                    modal.processing(false);
                });
            }
        },
        mounted() {
            this.getCarrierDetails();
            this.getDrivers();
        }
    }
</script>

<style lang="scss" scoped>
.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}
</style>