<template>
    <div class="save-carrier-wrapper">

        <div v-if="isLoading([''])" class="spinner-flex"><Spinner/></div>

        <div class=" grid row-15" v-if="item">
            <Input name="Name" v-model="item.name" :error="errors.name" placeholder="Enter name"/>
            <Input name="Last name" v-model="item.lastname" :error="errors.lastname" placeholder="Enter last name"/>
            <Input name="Contact phone" v-model="item.contactPhone" :error="errors.contactPhone" placeholder="Enter contact phone"/>
            <Input name="Email address" v-model="item.email" :error="errors.email" placeholder="Enter email"/>

            <div class="switch-item">
                <div class="name">Is driver the owner?</div>
                <Switch v-model="item.isOwner"/>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        props: ['modal', 'item', 'errors'],
    }
</script>

<style lang="scss" scoped>
.save-carrier-wrapper {
    padding: 20px;
    position: relative;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}
</style>